.dashboard{
    display: grid;
    grid-template-rows: 1fr 3fr;
    height: 100vh;

    // top of dashboard
    &__top{
        display: flex;
        justify-content: space-between;
    }

    &__logo{
        
        &-1{
            height: 100px;
        }
    }

    &__notifications{
        margin-top: 1%;
        width: 100%;
        height: 15rem;
        border: 2px solid $color-gray-dark;
    }

    &__logout{
        width: 10%;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    // bottom of dashboard

    &__bottom{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__training,
    &__tracker,
    &__manager{
        @include dashboardCard;
    }
}