// COLORS
$color-gray-light: #E8E8E8;
$color-gray-dark: #333333;

$color-red-light: #FF0000;
$color-red-dark: #B90504;

$color-white: #F6F6F6;



// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;

// FONT
$default-font-size: 1.6rem;

      
