.aboutUs{
    margin-top: -30px;
    margin-bottom: 10px;
    height: 100%;
    background-image: linear-gradient(to bottom right, $color-gray-light, $color-gray-dark);
    // transform: skewY(-4deg);
    &__header{
        color: white;
        font-size: 4rem;
        font-weight: 500;
        margin-left: 3%;
    }

    &__content{
        color: white;
        font-size: 2rem;
        margin-left: 20%;
        margin-bottom: 5%;
    }
}