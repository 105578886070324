.Uls{
  background-color: white;
}

.ULimages {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 450px));
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;

  



  img {
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;
  }
}

.ULimgCard{
  height: 120%;
  width: 100%;

  &__container{


  }

  &__text{
      background-color: $color-gray-dark;
      color: white;
      opacity: .5;
      transform: translateY(-50%);
      height: 6%;
      transition: all ease .3s;
      overflow: hidden;

      
      &-title{
          font-size: 2rem;
          margin-left: 5px;
          margin-right: 5px;
          // padding-bottom: 30px;
      }

      &-description{
          font-size: 1.3rem;
          margin-left: 10px;
          margin-right: 10px;
      }

      &:hover{
          height: 50%;
      }
  }
}