.footer{
    height: 10vh;
    background-color: $color-gray-dark;
    transform: translateY(-10vh);
    opacity: .7;

    &__row{
        height: 100%;
        width: 100%;
        max-width: none;
        background-color: $color-gray-dark;
        opacity: .7;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;

        &-link{
            color: white;
            font-size: 2.1rem;
            letter-spacing: 1.5px;
            transition: all ease .3s;
            // opacity: 1;

            &:hover{
                text-decoration: none;
                color: $color-white;
                opacity: .6;
                font-size: 2.2rem;
                letter-spacing: 1.7px;
            }

        }
    }
}