.login{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    background-color: $color-gray-light;
    height: 100vh;
    width: 100vw;

    &__logo{
        display: grid;
        grid-column: 2;
        width: 12vw;
        height: 1rem;

        &--1{
            width: 100%;
        }
    }


    &__portal{
        margin-top: 10%;
        border: 1px solid $color-gray-dark;
        grid-column: 2;
        background-color: white;
        width: 100%;
        height: 70%;

        &-container{
            width: 100%;
            &:not(:last-child){
                margin-top: 2.5%;
            }

            p{
                color: $color-red-light;
                font-size: 1.7rem;
            }
        }

        &-inputs{
            padding: 2rem;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr;
        }

        &-input{
            border: 1px solid $color-gray-dark;
            border-radius: 5px;
            height: 5rem;
            width: 100%;
        }

        &-login{
            border: 1px solid $color-gray-dark;
            background-color: $color-red-light;
            color: $color-white;
            font-size: 3rem;
            border-radius: 5px;
            height: 5rem;
            width: 42vw;
        }

        &-forgot{
            background: none;
            border: none;
            color: $color-red-light;
            transition: all ease .3s;

            &:hover{
                font-size: 1.8rem;
            }
        }
    }
    

    
}