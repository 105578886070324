.stopwatch{
    display: grid;
    grid-template-rows: 1fr 1fr 3fr;
    height: 100%;
    width: 100%;

    &-timer{
        display: flex;
        justify-content: center;
        height: 8rem;
        margin: 2rem;
        font-size: 4.5rem;

        background-color: $color-white;
    }

    &-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-right: 2rem;
        margin-left: 2rem;

        &-btn{
            height: 6rem;
        }
    }

    &-days{
        display: grid;
        grid-template-rows: 15% 1fr 15%;
        background-color: $color-white;
        margin: 2rem;

        &-title{
            display: flex;
            justify-content: center;
            font-size: 2.3rem;
            font-weight: 700;
        }

        &-btn{
            display: flex;
            justify-content: center;

            & button{
                width: 50%;
                height: 100%;
                border-radius: 5px;
                transform: translateY(25px);
                border: solid 1px $color-red-dark;
                background-color: $color-gray-light;
            }
        }
    }
}