
.header{
    z-index: 10;


    
    &__row{
        height: 40px;
        width: 100%;
        max-width: none;
        background-color: $color-gray-dark;
        opacity: .7;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;

        &-link{
            color: $color-white;
            font-weight: 600;
            font-size: 1.9rem;
            letter-spacing: 1.5px;
            transition: all ease .3s;
            text-decoration: none;

            &:hover{
                text-decoration: none;
                color: $color-white;
                opacity: .6;
                font-size: 2.2rem;
                letter-spacing: 1.7px;
            }

        }
    }
}

.main{
    // display: grid;
    // grid-template-rows: 1fr 1fr;

    margin: 0 auto;
    align-items: center;
}

// .main__logo{
//     width: 30%;

//     &-img{
//         width: 35%;
//     }
// }
