.card {

    // FUNCTIONALITY
    perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: 52rem;
    background: none;
    border: none;
    margin-bottom: 12vh;

    &__side {
        height: 52rem;
        transition: all .8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        backface-visibility: hidden;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 1.5rem 4rem rgba($color-gray-dark, .15);

        &--front {
            background-color: $color-white;

        }

        &--back {
            background-color: green;
            transform: rotateY(180deg);

            &-1 {
                background-image: linear-gradient(to right bottom, $color-gray-dark, $color-red-dark);
            }

            &-2{
                background-image: linear-gradient(to bottom, $color-gray-dark, $color-red-dark);

            }

            &-3{
                background-image: linear-gradient(to left bottom, $color-gray-dark, $color-red-dark);

            }
        }
    }

    &:hover &__side--front {
        transform: rotateY(-180deg);
    }

    &:hover &__side--back {
        transform: rotateY(0);
    }

    // FRONT SIDE STYLING
    &__picture {
        background-size: cover;
        height: 23rem;
        background-blend-mode: screen;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &--1 {
            background-image: linear-gradient(to right bottom, $color-gray-dark, $color-red-dark),
            url(#);

        }

        &--2 {
            background-image: linear-gradient(to bottom, $color-gray-dark, $color-red-dark),
            url(#);;
        }

        &--3 {
            background-image: linear-gradient(to left bottom, $color-gray-dark, $color-red-dark),
            url(#);;
        }
    }

    &__heading {
        font-size: 2.8rem;
        font-weight: 300;
        text-transform: uppercase;
        text-align: right;
        color: $color-white;
        position: absolute;
        top: 12rem;
        right: 2rem;
        width: 75%
    }

    &__heading-span {
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        &--1 {
            background-image: linear-gradient(to right bottom,
                rgba($color-gray-dark, .85),
                rgba($color-red-light, .85));
        }

        &--2 {
            background-image: linear-gradient(to bottom,
                rgba($color-gray-dark, .85),
                rgba($color-red-light, .85));
        }

        &--3 {
            background-image: linear-gradient(to left bottom,
                rgba($color-gray-dark, .85),
                rgba($color-red-light, .85));
        }
    }

    &__details {
        ul {
            list-style: none;
            width: 80%;
            margin: 0 auto;

            li {
                text-align: center;
                font-size: 1.5rem;
                padding: 1rem;

                &:not(:last-child) {
                    border-bottom: 1px solid $color-gray-light;
                }
            }
        }
    }


    // FRONT SIDE STYLING
    &__cta {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        text-align: center;
    }

    &__price-box {
        text-align: center;
        color: $color-white;
        margin-bottom: 8rem;
    }

    &__price-only {
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    &__price-value {
        font-size: 2.5rem;
        font-weight: 100;
    }
}
@media (max-width: 615px) {
    margin: 20px;
}