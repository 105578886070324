.landing{

    &__header{
        z-index: -1;
        height: 92vh;
        width: 100%;
        background-size: cover;
        background-position: left;
        background-image: url("./assets/homeBackgroundComp.jpg");
        margin-top: -40px;
        margin-bottom: 40px;


        &-intro--button{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 92vh;
    
        }
    }

    &__button{
        &,
        &:link,
        &:visited {
            text-transform: uppercase;
            text-decoration: none;
            padding: 1.5rem 4rem;
            display: inline-block;
            border-radius: 10rem;
            transition: all .2s;
            position: relative;
            font-size: $default-font-size;

            //Change for the <button> element
            border: none;
            cursor: pointer;
        }
        
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 2rem 3rem rgba(black,.4);
            color: $color-red-light;

            &::after {
                transform: scaleX(1.4) scaleY(1.6);
                opacity: 0;
            }
        }
        
        &:active,
        &:focus {
            outline: none;
            transform: translateY(-1px);
            box-shadow: 0 .1rem 1.5rem rgba($color-gray-dark,.4);
        }

        &--red {
            background-color: $color-gray-dark;
            color: $color-white;
            font-size: 2rem;
            opacity: .8;
    
            &::after {
                background-color: $color-gray-dark;
            }
        }
    }
}