.contact{
    display: grid;
    grid-template-rows: 10vh 1fr;
    background-image: linear-gradient(to right bottom, $color-gray-light, $color-gray-dark);
    height: 100vh;

    &__form{
        display: flex;
        justify-content: center;
    }
}

.contactUs__form{




}

.form {
    background-image: linear-gradient(105deg,
        rgba($color-white, .9) 0%,
        rgba($color-white, .9) 67%,
        transparent 50%),
        url("./assets/projects/ENCLAVE.jpg");

    display: grid;
    grid-template-rows: 20% 20% 20%;
    height: 80vh;
    width: 75%;

    &-group:not(:last-child) {
        margin-bottom: 2rem;
        margin-top: 2rem;
        margin-left: 2rem;
    }

    &__button{
        margin-top: 4rem;
        margin-left: 2rem;
        padding: 1.5rem 2rem;
        border-radius: 8px;
        background-color: rgba($color-red-light, .7);
        border: none;
        border-bottom: 3px solid transparent;
        width: 25%;
        display: block;
        transition: all .5s;
        color: $color-white;
        font-size: 2rem;

        &,
        &:link,
        &:visited {
            text-transform: uppercase;
            text-decoration: none;
            padding: 1.5rem 4rem;
            display: inline-block;
            transition: all .2s;
            position: relative;
    
            //Change for the <button> element
            border: none;
            cursor: pointer;
        }

        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 1rem 2rem rgba($color-gray-dark,.2);
    
            &::after {
                transform: scaleX(1.4) scaleY(1.6);
                opacity: 0;
            }
        }
        
        &:active,
        &:focus {
            outline: none;
            transform: translateY(-1px);
            box-shadow: 0 .5rem 1rem rgba($color-gray-dark,.5);
        }

        &::after {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            border-radius: 10rem;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all .4s;
        }

    }

    &__input {
        // margin-top: 8rem;
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rgba($color-gray-dark, .5);
        border: none;
        border-bottom: 3px solid transparent;
        width: 65%;
        display: block;
        transition: all .5s;


        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba($color-gray-dark, .1);
            border-bottom: 3px solid $color-white;
        }

        &:focus:invalid {
            border-bottom: 3px solid $color-red-dark;
        }

        &::-webkit-input-placeholder {
            color: $color-gray-dark;
        }
    }


    &__label {
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: .7rem;
        display: block;
        transition: all .3s;
    }
 
    &__input:placeholder-shown + &__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }
}
