@mixin clearfix {
    &::after{
        content: "";
        display: table;
        clear: both;
    }
}

@mixin dashboardCard{
    display: flex;
    justify-content: center;
    align-items: center;
    &-box{
        margin-bottom: 2rem;
        height: 90vh;
        width: 90%;
        background-color: $color-gray-dark;        
    } 
}